:host {
  display: block;
}

.choices {
  container-type: inline-size;
  ::slotted(div) {
    margin: 0;
    display: flex;
    flex-wrap: wrap;
    gap: var(--sc-choices-gap, 10px);
  }
}
.choices:not(.choices--auto-width) {
  ::slotted(div) {
    margin: 0;
    display: grid;
    gap: var(--sc-choices-gap, 10px);
  }
}

.breakpoint-lg,
.breakpoint-xl {
  ::slotted(div) {
    grid-template-columns: repeat(var(--columns, 1), 1fr);
  }
}
.choices {
  .choices__items {
    margin: 0;
    display: flex;
    flex-wrap: wrap;
    gap: var(--sc-choices-gap, 10px);
  }
}

.choices:not(.choices--auto-width) {
  .choices__items {
    display: grid;
    gap: var(--sc-choices-gap, 10px);
  }
}

.choices {
  border: none;
  padding: 0;
  margin: 0;
  min-width: 0;
  margin: 0;

  .choices__label {
    font-family: var(--sc-input-font-family);
    font-size: var(--sc-input-font-size-medium);
    font-weight: var(--sc-input-font-weight);
    color: var(--sc-input-color);
    padding: 0 var(--sc-spacing-xx-small);
  }
}

.choices__items {
  display: grid;
  gap: var(--sc-choices-gap, 10px);
  grid-auto-rows: 1fr;
}

.choices__items {
  grid-template-columns: repeat(var(--columns, 1), 1fr);
}

@container (max-width: 419px) {
  .choices__items {
    grid-template-columns: repeat(1, 1fr);
  }
}
